import { UserState } from './typing';

export const state: UserState = {
  token: '',
  username: '',
  nickname: '',
  avatar: '',
  sessionid2: '',
  sessionid: '',
  extra: {},
  current_org: {},
  current_shop: {},
  role: undefined,
  allowRouters: [],
  org_list: [],
  shop_list: [],
  is_super_user: false,
  user_list: [],
  custome_data_user: {},
  custome_data_org: {},
  my_menu_data_list: [],
};
