import 'ant-design-vue/dist/antd.less';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Tabs,
  Tag,
  Input,
  InputNumber,
  Steps,
  Descriptions,
  Popover,
  List,
  ConfigProvider,
  Image,
  Collapse,
} from 'ant-design-vue';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import locale from './locales';
import App from './App.vue';


import MenuDrawer from '@/components/info-modal/menuDrawer/index.vue';


import {
  ProProvider,
  PageContainer,
  TransformVnode,
} from '@/components';
import { useIcons } from '@/icons';
import Authority from './utils/authority/authority.vue';
import './app.less';
import './router/router-guards';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
// if (process.env.NODE_ENV == 'production') {
//   Sentry.init({
//     dsn: 'http://20d465198a2440d3b1cb134379a5e8be@8.140.118.52:19000/8',
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

const app = createApp(App);

app
  .use(router)
  .use(locale as any)
  .use(store)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Form)
  .use(Button)
  .use(Tabs)
  .use(Tag)
  .use(Input)
  .use(Image)
  .use(InputNumber)
  .use(Steps)
  .use(Descriptions)
  .use(Popover)
  .use(List)
  .use(ConfigProvider)
  .use(ProProvider)
  .use(Collapse)
  .component(PageContainer.name, PageContainer)
  .component(TransformVnode.name, TransformVnode)
  .component(Authority.name, Authority)

useIcons(app);

app.mount('#app');
