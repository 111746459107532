import { App } from 'vue';
import {
  ScissorOutlined,
  TagOutlined,
  DeploymentUnitOutlined,
  NotificationOutlined,
  UnlockOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UserOutlined,
  ArrowUpOutlined,
  LockOutlined,
  ToolOutlined,
  ExpandOutlined,
  BlockOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons-vue';

export const useIcons = (app: App) => {
  app.component(UserOutlined.displayName, UserOutlined);
  app.component(ToolOutlined.displayName, ToolOutlined);
  app.component(ExpandOutlined.displayName, ExpandOutlined);
  app.component(BlockOutlined.displayName, BlockOutlined);
  app.component(LockOutlined.displayName, LockOutlined);
  app.component(ScissorOutlined.displayName, ScissorOutlined);
  app.component(TagOutlined.displayName, TagOutlined);
  app.component(DeploymentUnitOutlined.displayName, DeploymentUnitOutlined);
  app.component(NotificationOutlined.displayName, NotificationOutlined);
  app.component(UnlockOutlined.displayName, UnlockOutlined);
  app.component(ArrowLeftOutlined.displayName, ArrowLeftOutlined);
  app.component(ArrowRightOutlined.displayName, ArrowRightOutlined);
  app.component(ArrowUpOutlined.displayName, ArrowUpOutlined);
  app.component(ArrowDownOutlined.displayName, ArrowDownOutlined);
};
