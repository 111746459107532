import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { ResponseBody } from '@/api/typing';
import { notification } from 'ant-design-vue';
import { STORAGE_SESSIONID, STORAGE_SESSIONID2 } from '@/store/mutation-type';
import { sourceStore } from '@/utils/local-storage';

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
export const REQUEST_TOKEN_KEY = 'sessionid';

export interface ResponseData {
  success: boolean;
  message: string;
  status_code: number;
  result: any;
  dialog: number;
}

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error: AxiosError): Promise<any> => {
  if (error.response) {
    const { data = {}, status, statusText } = error.response;
    // 403 无权限
    if (status === 403) {
      notification.error({
        message: 'Forbidden',
        description: (data && data.message) || statusText,
      });
    }
    // 401 未登录/未授权
    if (status === 401 && data.result && data.result.isLogin) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed',
      });
    }
  }
  return Promise.reject(error);
};

// 请求拦截器
const requestHandler = (
  config: AxiosRequestConfig,
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const sessionid2 = sourceStore.get(STORAGE_SESSIONID2);
  const sessionid = sourceStore.get(STORAGE_SESSIONID);
  // 如果 sessionid2 存在
  // 让每个请求携带自定义 sessionid2
  if (sessionid2) {
    config.headers['sessionid2'] = sessionid2;
  }
  if (sessionid) {
    config.headers['sessionid'] = sessionid;
  }
  config.headers['requesttype'] = 'ant';
  return config;
};

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler);

// 响应拦截器
const responseHandler = (
  response: AxiosResponse,
): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  const res: ResponseData = response.data;
  if (res.success) {
    if (response.config.params?.show_message && res.message) {
      notification.info({
        message: '提示',
        description: res.message,
      });
    }
    return res.result;
  } else if (res.status_code == 1) {
    notification.error({
      message: '登录过期',
      description: '请重新登录',
    });
    // const router = useRouter();
    // const store = useStore();
    //
    // store.dispatch(`user/${LOGOUT}`).then(() => {
    //   router.push({ path: '/user/login' });
    // });
  } else if (res.status_code == 4) {
    notification.error({
      message: '服务器端错误',
      description: res.message,
    });
  } else if (res.status_code == 14) {
    notification.error({
      message: '服务器端错误',
      description: res.message,
    });
  }
  return Promise.reject(res);
};

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler);

export { AxiosResponse };

export default request;
