import store from 'store';
import expirePlugin from 'store/plugins/expire';

// plugin usage:
store.addPlugin(expirePlugin);
const reg = /static_file_[A-Za-z0-9]*_?([A-Za-z0-9]*)/;
const namespace_arr:any = reg.exec(window.location.href);
let namespace:any = null;
if(namespace_arr && namespace_arr.length>0){
  namespace = namespace_arr[0].trim();
}
const namespaceStore = namespace?store.namespace(namespace):store;
const sourceStore = store.namespace('source');
//
// const autoNameSpacePlugin = ()=> {
//   return {
//     set: (super_fn:any, key:any, value:any)=>{
//       return namespace?namespaceStore.set(key, value):super_fn();
//     },
//     get: (super_fn:any, key:any, default_value:any)=>{
//       return namespace?namespaceStore.get(key, default_value):super_fn();
//     },
//   };
// };
// store.addPlugin(autoNameSpacePlugin);

const getStoreByName = (name:string)=>{
  if(name==namespace){
    return namespaceStore;
  }else if(name=='source'){
    return sourceStore;
  }else if(!name){
    return store;
  }else{
    return store.namespace(name);
  }
};

export { namespaceStore as localStorage, sourceStore, getStoreByName };

export default namespaceStore;
