import {createRouter, createWebHistory} from 'vue-router';
import {MenuDataItem} from './typing';
import UserLayout2 from '@/layouts/user-layout2.vue';

export const routes: MenuDataItem[] = [
  {
    name: 'index',
    path: '/',
    redirect: '/user/login',
    component: UserLayout2,
    children: [
      {
        path: '/user/login',
        name: 'login',
        meta: { title: 'pages.login.accountLogin.tab' },
        component: () => import('@/views/user/wxlogin.vue'),
      },
    ],
  },
];

export const staticRoutes: MenuDataItem[] = [
  {
    path: '/user',
    name: 'user',
    meta: { hideInMenu: true, title: 'pages.layouts.userLayout.title' },
    component: UserLayout2,
    children: [
      {
        path: '/user/login',
        name: 'login',
        meta: { title: 'pages.login.accountLogin.tab' },
        component: () => import('@/views/user/wxlogin.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/user/wxlogin.vue'),
  },
];

const reg = /static_file[_A-Za-z0-9]*/;
const namespace_arr: any = reg.exec(window.location.href);
let namespace: any = null;
if (namespace_arr && namespace_arr.length > 0) {
  namespace = namespace_arr[0].trim();
}
const router = createRouter({
  history: createWebHistory(namespace ? namespace : process.env.VUE_APP_PUBLIC_PATH),
  routes: staticRoutes,
});

export default router;
