import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function set_column_setting_for_person(params: Record<string, any>) {
  return request.post('/sys/set_column_setting_for_person', params);
}
export async function set_column_setting_for_org(params: Record<string, any>) {
  return request.post('/sys/set_column_setting_for_org', params);
}
export async function set_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/set_custom_data_by_flag', params);
}

export async function get_custom_data_by_flag(params: Record<string, any>) {
  return request.post('/sys/get_custom_data_by_flag', params);
}

export async function query_my_column_setting_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sys/query_my_column_setting_list', params);
}

export async function bind_user_and_devie_token(params: Record<string, any>) {
  return request.post('/sys/bind_user_and_devie_token', params);
}

export async function refresh_client_version(params: Record<string, any>) {
  return request.post('/sys/refresh_client_version', params);
}

export async function create_security_code(params: Record<string, any>) {
  return request.post('/sys/create_security_code', params);
}

export async function query_run_time_script_list_for_index(params: Record<string, any>) {
  return request.post('/sys/query_run_time_script_list_for_index', params);
}

export async function html_json(params: Record<string, any>) {
  return request.post('/sys/html_json', params);
}

export async function wechat_login_page(params: Record<string, any>) {
  return request.post('/sys/wechat_login_page', params);
}

export async function query_celery_runing_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/oa/query_celery_runing_list', params);
}

export async function delete_user(params: Record<string, any>) {
  return request.post('/sys/delete_user', params);
}

export async function get_last_data(params: Record<string, any>) {
  return request.post('/sys/get_last_data', params);
}

export async function send_sms_code_login(params: Record<string, any>) {
  return request.post('/sys/send_sms_code_login', params);
}

export async function get_log_detail(params: Record<string, any>) {
  return request.post('/sys/get_log_detail', params);
}

export async function relieve_user_and_code(params: Record<string, any>) {
  return request.post('/sys/relieve_user_and_code', params);
}

export async function get_wechat_setting_by_group_id(params: Record<string, any>) {
  return request.post('/sys/get_wechat_setting_by_group_id', params);
}

export async function auto_recover_database_copy_status(params: Record<string, any>) {
  return request.post('/sys/auto_recover_database_copy_status', params);
}

export async function get_user_info(params: Record<string, any>) {
  return request.post('/sys/get_user_info', params);
}

export async function recover_security_code(params: Record<string, any>) {
  return request.post('/sys/recover_security_code', params);
}

export async function get_html_content_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/get_html_content_by_flag', params);
}

export async function my_userinfo(params: Record<string, any>) {
  return request.post('/sys/my_userinfo', params);
}

export async function simple_http_login(params: Record<string, any>) {
  return request.post('/sys/simple_http_login', params);
}

export async function user_icon(params: Record<string, any>) {
  return request.post('/sys/user_icon', params);
}

export async function update_problem_reason(params: Record<string, any>) {
  return request.post('/sys/update_problem_reason', params);
}

export async function check_external_info(params: Record<string, any>) {
  return request.post('/sys/check_external_info', params);
}

export async function create_html_pdf(params: Record<string, any>) {
  return request.post('/sys/create_html_pdf', params);
}

export async function send_sms_code(params: Record<string, any>) {
  return request.post('/sys/send_sms_code', params);
}

export async function delete_security_code(params: Record<string, any>) {
  return request.post('/sys/delete_security_code', params);
}

export async function delete_sys_cache(params: Record<string, any>) {
  return request.post('/oa/delete_sys_cache', params);
}

export async function get_database_copy_mode(params: Record<string, any>) {
  return request.post('/sys/get_database_copy_mode', params);
}

export async function logout(params: Record<string, any>) {
  return request.post('/sys/logout', params);
}

export async function sync_cookie(params: Record<string, any>) {
  return request.post('/sys/sync_cookie', params);
}

export async function query_org_by_flag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_org_by_flag_list', params);
}

export async function set_script_flag_show(params: Record<string, any>) {
  return request.post('/sys/set_script_flag_show', params);
}

export async function get_userinfo(params: Record<string, any>) {
  return request.post('/sys/get_userinfo', params);
}

export async function compair_pageandapi(params: Record<string, any>) {
  return request.post('/sys/compair_pageandapi', params);
}

export async function get_user_weixin_token(params: Record<string, any>) {
  return request.post('/sys/get_user_weixin_token', params);
}

export async function simple_login(params: Record<string, any>) {
  return request.post('/sys/simple_login', params);
}

export async function query_user_code_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_user_code_list', params);
}

export async function query_database_copy_info_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/sys/query_database_copy_info_list', params);
}

export async function query_script_signal_value_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/sys/query_script_signal_value_list', params);
}

export async function import_xls_first(params: Record<string, any>) {
  return request.post('/sys/import_xls_first', params);
}

export async function jyx_login(params: Record<string, any>) {
  return request.post('/sys/jyx_login', params);
}

export async function update_my_user_icon(params: Record<string, any>) {
  return request.post('/sys/update_my_user_icon', params);
}

export async function u(params: Record<string, any>) {
  return request.post('/sys/u', params);
}

export async function query_run_time_script_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_run_time_script_list', params);
}

export async function set_wechat_setting_by_group_id(params: Record<string, any>) {
  return request.post('/sys/set_wechat_setting_by_group_id', params);
}

export async function update_user_email(params: Record<string, any>) {
  return request.post('/sys/update_user_email', params);
}

export async function query_monitor_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_monitor_name_list', params);
}

export async function jyx_sync_login(params: Record<string, any>) {
  return request.post('/sys/jyx_sync_login', params);
}

export async function get_data_by_id(params: Record<string, any>) {
  return request.post('/sys/get_data_by_id', params);
}

export async function update_security_code(params: Record<string, any>) {
  return request.post('/sys/update_security_code', params);
}

export async function query_sys_ap_status_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_sys_ap_status_list', params);
}

export async function update_user_erp_username(params: Record<string, any>) {
  return request.post('/sys/update_user_erp_username', params);
}

export async function query_user_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_user_list', params);
}

export async function get_celery_task_info(params: Record<string, any>) {
  return request.post('/sys/get_celery_task_info', params);
}

export async function get_plan_excel_import_setting_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/get_plan_excel_import_setting_by_flag', params);
}

export async function get_user_wechat_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/get_user_wechat_org_list', params);
}

export async function erp_login(params: Record<string, any>) {
  return request.post('/sys/erp_login', params);
}

export async function query_celery_beat_interval_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/oa/query_celery_beat_interval_list', params);
}

export async function create_user(params: Record<string, any>) {
  return request.post('/sys/create_user', params);
}

export async function qrcode2(params: Record<string, any>) {
  return request.post('/sys/qrcode2', params);
}

export async function wechat_login(params: Record<string, any>) {
  return request.post('/sys/wechat_login', params);
}

export async function recover_problem_reason(params: Record<string, any>) {
  return request.post('/sys/recover_problem_reason', params);
}

export async function text_icon(params: Record<string, any>) {
  return request.post('/sys/text_icon', params);
}

export async function query_log_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_log_list', params);
}

export async function create_problem_reason(params: Record<string, any>) {
  return request.post('/sys/create_problem_reason', params);
}

export async function html_pdf(params: Record<string, any>) {
  return request.post('/sys/html_pdf', params);
}

export async function simple_super_login(params: Record<string, any>) {
  return request.post('/sys/simple_super_login', params);
}

export async function query_problem_reason_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_problem_reason_list', params);
}

export async function update_user(params: Record<string, any>) {
  return request.post('/sys/update_user', params);
}

export async function check_init_data_all(params: Record<string, any>) {
  return request.post('/sys/check_init_data_all', params);
}

export async function set_database_copy_mode(params: Record<string, any>) {
  return request.post('/sys/set_database_copy_mode', params);
}

export async function change_password(params: Record<string, any>) {
  return request.post('/sys/change_password', params);
}

export async function upload_device_store_excel(params: Record<string, any>) {
  return request.post('/sys/upload_device_store_excel', params);
}

export async function wechat_robot_bind(params: Record<string, any>) {
  return request.post('/sys/wechat_robot_bind', params);
}

export async function change_password_by_code(params: Record<string, any>) {
  return request.post('/sys/change_password_by_code', params);
}

export async function sentry_webhook(params: Record<string, any>) {
  return request.post('/sys/sentry_webhook', params);
}

export async function query_pdi_unread_info_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_pdi_unread_info_list', params);
}

export async function str_to_pinyin(params: Record<string, any>) {
  return request.post('/sys/str_to_pinyin', params);
}

export async function create_value(params: Record<string, any>) {
  return request.post('/oa/create_value', params);
}

export async function query_person_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_person_name_list', params);
}

export async function set_plan_excel_import_setting_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/set_plan_excel_import_setting_by_flag', params);
}

export async function query_security_code_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_security_code_list', params);
}

export async function relieve_user_and_devie_token(params: Record<string, any>) {
  return request.post('/sys/relieve_user_and_devie_token', params);
}

export async function reg_user(params: Record<string, any>) {
  return request.post('/sys/reg_user', params);
}

export async function qrcode_login_scan(params: Record<string, any>) {
  return request.post('/sys/qrcode_login_scan', params);
}

export async function delete_problem_reason(params: Record<string, any>) {
  return request.post('/sys/delete_problem_reason', params);
}

export async function get_user_device_token(params: Record<string, any>) {
  return request.post('/sys/get_user_device_token', params);
}

export async function query_problem_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_problem_list', params);
}

export async function qrcode_login_check(params: Record<string, any>) {
  return request.post('/sys/qrcode_login_check', params);
}

export async function check_login(params: Record<string, any>) {
  return request.post('/sys/check_login', params);
}

export async function oauth2(params: Record<string, any>) {
  return request.post('/sys/oauth2', params);
}

export async function get_client_version(params: Record<string, any>) {
  return request.post('/sys/get_client_version', params);
}

export async function qrcode_login_string(params: Record<string, any>) {
  return request.post('/sys/qrcode_login_string', params);
}

export async function get_user_by_code(params: Record<string, any>) {
  return request.post('/sys/get_user_by_code', params);
}

export async function query_wechat_commands_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_wechat_commands_list', params);
}

export async function recover_user(params: Record<string, any>) {
  return request.post('/sys/recover_user', params);
}

export async function get_cache_wechat_content_by_group(params: Record<string, any>) {
  return request.post('/sys/get_cache_wechat_content_by_group', params);
}

export async function text_icon_group(params: Record<string, any>) {
  return request.post('/sys/text_icon_group', params);
}

export async function query_person_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_person_list', params);
}

export async function query_datajson_by_flag_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/sys/query_datajson_by_flag_list', params);
}

export async function simple_hand_login(params: Record<string, any>) {
  return request.post('/sys/simple_hand_login', params);
}

export async function bind_user_and_code(params: Record<string, any>) {
  return request.post('/sys/bind_user_and_code', params);
}

export async function send_sms_code_reg(params: Record<string, any>) {
  return request.post('/sys/send_sms_code_reg', params);
}

export async function query_log_tag_values_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/sys/query_log_tag_values_list', params);
}

export async function share_pc_login(params: Record<string, any>) {
  return request.post('/sys/share_pc_login', params);
}

export async function code_login(params: Record<string, any>) {
  return request.post('/sys/code_login', params);
}

export async function get_user_code(params: Record<string, any>) {
  return request.post('/sys/get_user_code', params);
}

export async function inport_user_temp(params: Record<string, any>) {
  return request.post('/sys/inport_user_temp', params);
}

export async function set_html_content_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/set_html_content_by_flag', params);
}

export async function query_celery_beat_crontab_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/oa/query_celery_beat_crontab_list', params);
}
