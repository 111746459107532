import { ActionTree } from 'vuex';
import type { RouteRecordRaw } from 'vue-router';
import { UserState } from './typing';
import { RootState } from '@/store/root-state';
import {
  RESET_CURRENT_USER,
  SET_CURRENT_ORG,
  SET_INFO,
  SET_SHOP_LIST,
  SET_CURRENT_SHOP,
  SET_ORGLIST,
  SET_ROUTERS,
  SET_SESSIONID2,
  SET_SESSIONID,
  SET_TOKEN,
  SET_USER_LIST,
  SET_IS_SUPER_USER,
  SET_CUSTOME_DATA_USER,
  SET_MY_MENU_DATA_LIST, SET_JSC_DATA_LIST, SET_JUMP_NAMPSPACE,
} from './mutations';
import {
  LoginParams,
  postAccountLogin,
  getCurrentUser,
  postLogout,
  queryMyShopList,
  UserInfo,
} from '@/api/user/login';
import { simpleLoginMaintain, querySwitchUserList, checkIsSuperUser, switchUser, my_orginfo } from '@/api/sys';
import { query_my_column_setting_list } from '@/api/sys-model';
import { get_my_menu } from '@/api/permission/menu-manage';
import { default as router, routes } from '@/router';
import { filterMenu } from '@/utils/menu-util';
import { hasAuthority, filterChildRoute } from '@/utils/authority';
import { generatorDynamicRouter } from '@/router/generator-routers';
import { localStorage as ls, sourceStore} from '@/utils/local-storage';
import { defineAsyncComponent, h } from 'vue';
import { STORAGE_CURRENT_MENU_KEY, STORAGE_CURRENT_JSC_KEY } from '@/store/mutation-type';
import { MenuDataItem } from '@/router/typing';
import RouteView from '@/layouts/route-view.vue';
import RouteView2 from '@/layouts/route-view2.vue';
export const LOGIN = 'LOGIN';
export const MY_ORGINFO = 'MY_ORGINFO';
export const LOGIN_YUN_WEI = 'LOGIN_YUN_WEI';
export const LOGOUT = 'LOGOUT';
export const GET_INFO = 'GET_INFO';
export const GET_SHOP = 'GET_SHOP';
export const GENERATE_ROUTES = 'GenerateRoutes';
export const GENERATE_ROUTES_DYNAMIC = '';
export const CHANGE_CURRENT_ORG = 'CHANGE_CURRENT_ORG';
export const CHANGE_CURRENT_SHOP = 'CHANGE_CURRENT_SHOP';
export const SWITCH_USER = 'SWITCH_USER';
export const QUERY_USER_LIST = 'QUERY_USER_LIST';
export const CHECK_IS_SUPER_USER = 'CHECK_IS_SUPER_USER';
export const GET_CUSTOME_DATA_USER = 'GET_CUSTOME_DATA_USER';
export const UPDATE_CUSTOME_DATA_USER = 'UPDATE_CUSTOME_DATA_USER';
export const GET_MY_MENU_DATA_LIST = 'GET_MY_MENU_DATA_LIST';

export const generator = (routeMap: MenuDataItem[]): MenuDataItem[] => {
  return routeMap
    .map(item => {
      const { title, hideInMenu, hideChildrenInMenu, target, icon, authority } = item.meta || {};
      const currentRouter: any = {
        // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
        path: item.path,
        // 路由名称，建议唯一
        name: item.name,
        // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
        meta: {
          title,
          icon: icon || undefined,
          hideInMenu,
          hideChildrenInMenu,
          target,
          authority,
        },
        // 该路由对应页面的 组件 (动态加载 @/views/ 下面的路径文件)
        component: item.component,
      };

      // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
      if (!currentRouter.path.startsWith('http')) {
        currentRouter.path = currentRouter.path.replace('//', '/');
      }

      // 重定向
      item.redirect && (currentRouter.redirect = item.redirect);

      // 子菜单，递归处理
      if (item.children) {
        currentRouter.children = generator(item.children);
        if (currentRouter.children && currentRouter.children.length <= 0) {
          delete currentRouter.children;
        }
      }
      return currentRouter;
    })
    .filter(item => item);
};

const init_menu = (commit: any) => {
  const my_menu_data_list: any[] = ls.get(STORAGE_CURRENT_MENU_KEY) || []; //当前菜单
  const jsc_data_list: any[] = ls.get(STORAGE_CURRENT_JSC_KEY) || []; //驾驶舱
  const allRoutes = filterMenu(generator(routes));
  const permissionsKey = my_menu_data_list?.map((permission: any) => permission);
  const allowRoutes = allRoutes.filter(route => {
    // parnent route filter
    const hasAllow = hasAuthority(route, permissionsKey);
    if (route.children && route.children.length > 0) {
      // current route children filter
      route.children = filterChildRoute(route, permissionsKey);
    }
    if (hasAllow) {
      return hasAllow;
    } else if (route.children && route.children.length > 0) {
      return true;
    }
    return hasAllow;
  });

  const {
    // eslint-disable-next-line
    children: _,
    ...mainRoute
  } = routes[0];
  const route = {
    ...mainRoute,
    children: allowRoutes,
  };
  router.addRoute(route);
  commit(SET_ROUTERS, allowRoutes);
  return allowRoutes;
};

export const actions: ActionTree<UserState, RootState> = {
  [LOGIN]({ commit }, info: LoginParams) {
    return new Promise((resolve, reject) => {
      // call ajax
      postAccountLogin(info)
        .then((res: any) => {
          commit(SET_TOKEN, res);
          commit(SET_SESSIONID, res.sessionid);
          commit(SET_ORGLIST, res.org_list.data);
          const org = res.org_list.data[0];
          commit(SET_CURRENT_ORG, org);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [MY_ORGINFO]({ commit }, info: LoginParams) {
    return new Promise((resolve, reject) => {
      // call ajax
      my_orginfo(info)
        .then((res: any) => {
          commit(SET_TOKEN, res);
          commit(SET_SESSIONID, res.sessionid);
          commit(SET_ORGLIST, res.org_list.data);

          if(res.org_list.data.length>0){
            let org = res.org_list.data[0];
            if(info.org_id){
              const org_list = res.org_list.data.filter((item:any)=>item.id==info.org_id);
              if(org_list.length>0){
                org = org_list[0];
              }
            }
            commit(SET_CURRENT_ORG, org);
          }

          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHANGE_CURRENT_ORG]({ commit }, org: any) {
    return new Promise(resolve => {
      commit(SET_CURRENT_ORG, org);
      resolve(null);
    });
  },
  // 运维登录
  [LOGIN_YUN_WEI]({ commit }, info: any) {
    return new Promise(resolve => {
      simpleLoginMaintain(info).then((res: any) => {
        commit(SET_SESSIONID2, res.sessionid2);
        resolve(null);
      });
    });
  },
  // 获取用户信息
  [GET_INFO]({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrentUser()
        .then((res: UserInfo) => {
          commit(SET_INFO, res);
          resolve(res);
        })
        .catch(err => {
          // 获取登录用户信息后，直接清理掉当前 token 并强制让流程走到登录页
          commit(SET_TOKEN, null);
          commit(SET_SESSIONID, null);
          commit(SET_SESSIONID2, null);
          commit(SET_ORGLIST, null);
          commit(SET_CURRENT_ORG, null);
          commit(SET_SHOP_LIST, null);
          commit(SET_CURRENT_SHOP, null);
          reject(err);
        });
    });
  },
  // 获取门店信息
  [GET_SHOP]({ commit }, info: any) {
    return new Promise((resolve, reject) => {
      queryMyShopList(info)
        .then((res: any) => {
          commit(SET_SHOP_LIST, res.data);
          if (res.data && res.data.length > 0) {
            const shop = res.data[0];
            commit(SET_CURRENT_SHOP, shop);
          }
          resolve(res);
        })
        .catch(err => {
          // 获取登录用户信息后，直接清理掉当前 token 并强制让流程走到登录页
          commit(SET_TOKEN, null);
          commit(SET_SESSIONID2, null);
          commit(SET_ORGLIST, null);
          commit(SET_CURRENT_ORG, null);
          commit(SET_SHOP_LIST, null);
          commit(SET_CURRENT_SHOP, null);
          reject(err);
        });
    });
  },
  [CHANGE_CURRENT_SHOP]({ commit }, shop: any) {
    return new Promise(resolve => {
      commit(SET_CURRENT_SHOP, shop);
      resolve(null);
    });
  },
  [GENERATE_ROUTES]({ commit }) {
    return new Promise<RouteRecordRaw[]>(resolve => {
      // 修改这里可以进行接口返回的对象结构进行改变
      // 亦或是去掉 info.role 使用别的属性替代
      // 任何方案都可以，只需要将最后拼接构建好的路由数组使用
      // router.addRoute() 添加到当前运行时的路由中即可
      // info.role
      const allowRoutes = init_menu(commit);
      // 添加到路由表
      resolve(allowRoutes);
    });
  },
  // 从后端获取路由表结构体，并构建前端路由
  [GENERATE_ROUTES_DYNAMIC]({ commit }) {
    return new Promise<RouteRecordRaw>(resolve => {
      generatorDynamicRouter()
        .then((routes: RouteRecordRaw) => {
          console.log('generatorDynamicRouter', routes);
          const allowRoutes = routes.children || [];
          // 添加到路由表
          router.addRoute(routes);
          commit(SET_ROUTERS, allowRoutes);
          resolve(routes);
        })
        .catch(err => {
          console.error('generatorDynamicRouter', err);
        });
    });
  },
  [LOGOUT]({ commit }) {
    return new Promise<void>(resolve => {
      postLogout().finally(() => {
        commit(SET_TOKEN, null);
        commit(SET_ORGLIST, null);
        commit(SET_CURRENT_ORG, null);
        commit(SET_SESSIONID2, null);
        commit(SET_SHOP_LIST, null);
        commit(SET_CURRENT_SHOP, null);
        commit(RESET_CURRENT_USER);
        resolve();
      });
    });
  },
  [QUERY_USER_LIST]({ commit }) {
    return new Promise<void>(resolve => {
      querySwitchUserList().then(res => {
        commit(SET_USER_LIST, res);
        resolve();
      });
    });
  },
  [SWITCH_USER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      switchUser(params)
        .then(res => {
          commit(SET_TOKEN, res);
          commit(SET_ORGLIST, res.org_list.data);
          const org = res.org_list.data[0];
          commit(SET_CURRENT_ORG, org);
          commit(SET_SESSIONID, res.sessionid);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHECK_IS_SUPER_USER]({ commit }, params: any) {
    return new Promise<void>(resolve => {
      checkIsSuperUser(params).then(res => {
        commit(SET_IS_SUPER_USER, res);
        resolve();
      });
    });
  },
  [GET_CUSTOME_DATA_USER]({ commit }, params: any) {
    return new Promise<void>(resolve => {
      query_my_column_setting_list(params).then(res => {
        const data: any = {};
        const person_data: any = {};
        res.data.map((item: any) => {
          if(item.person_id){
            person_data[item.flag] = JSON.parse(item.data);
          }else{
            data[item.flag] = JSON.parse(item.data);
          }

        });
        commit(SET_CUSTOME_DATA_USER, { data:data, person_data:person_data });
        resolve();
      });
    });
  },
  [UPDATE_CUSTOME_DATA_USER]({ commit }, params: any) {
    commit(UPDATE_CUSTOME_DATA_USER, { data:params.data, person_data:params.person_data });
  },
  [GET_MY_MENU_DATA_LIST]({ commit }, params: any) {
    return new Promise<void>(resolve => {
      get_my_menu({
        ...params,
        page_size: 2000,
      }).then(res => {
        const my_menu_data_list: any = [];
        res.data.map((item: any) => {
          my_menu_data_list.push(item.state);
        });
        commit(SET_MY_MENU_DATA_LIST, my_menu_data_list);
        init_menu(commit);
        resolve();
      });
    });
  },
  [SET_JUMP_NAMPSPACE]({ commit }, params: string) {
    commit(SET_JUMP_NAMPSPACE, params);
  },
};
